.leaflet-touch .leaflet-control-attribution{
    display: none;
}

@media screen and (max-width: 600px){
    .apexcharts-legend.apexcharts-align-center.position-bottom{
        flex-wrap: nowrap;
    }
}

input#date-picker-inline {
    cursor: pointer;
    background-color: #2B96D2;
}
